import React from 'react';
import ReactDOM from 'react-dom/client';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.scss';

import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux"
import store from "./store/index";
import AppRouter from './AppRouter'
import { HelmetProvider } from 'react-helmet-async'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
     <HelmetProvider>
    <AppRouter />
     </HelmetProvider>
  </Provider>
);

reportWebVitals();

import clsx from 'clsx'
import SvgLogo from './SvgLogo'
import { useEffect, useState } from 'react'

const Preloader =() => {
  const [hide, setHide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setHide(true);
    }, 2000);
  }, [])

  return <div id="preloader" className={clsx(hide && 'show')} >
      <div className="logos">
          <SvgLogo />
      </div>
  </div>
}

export default Preloader;

import {all,put} from 'redux-saga/effects';
import mainWatcher from "./main";

export default function* rootSaga() {
  try {
    yield all([
      mainWatcher()
    ]);
  } catch (error) {
    console.log("error", error);
    yield put({type:"ASN_FAILED", payload:{msg:"ネットワークをご確認ください", stop:true}})
  }
}

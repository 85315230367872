import React, { lazy, Suspense, useEffect} from 'react';
import { BrowserRouter, HashRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import MyPreloader from "./components/common/Preloader";
import { useDispatch } from 'react-redux'
const Top = lazy(() => import('./components/pages/Top'));
const NewsPage = lazy(() => import('./components/pages/NewsPage'));

const AppRouter = () => {
  const dispatch = useDispatch();
  useEffect(()=> {
  }, [])

  return (
       <Suspense fallback={ <MyPreloader show={false} /> }>
        <Router>
          <Routes>
            <Route path="/" element={<Top />} />
             <Route path="/news" element={<NewsPage />} />
            <Route path="*" element={<Top />} />
          </Routes>
        </Router>
       </Suspense>
  );
};


export default AppRouter;

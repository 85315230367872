import { put, takeLatest } from '@redux-saga/core/effects'

function*SAGA_GETS(action){
  const {query, key} = action.payload;
  yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:true}});
  try {
  }catch(error) {
    console.log("ERROR:SAGA_GETS", error);
  }

  yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:false}});
}


export default function* mainWatcher() {
  yield takeLatest("SAGA_GETS",  SAGA_GETS);
}

import React from 'react';
const SvgLogo = () => {
    return (
        <div id="svg_logo_div">
            <div className="img_area fade-in-1s">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 454 155" width="454" height="155">
<path className="st0 svg-elem-1" d="M99,29.2c0.1-0.4,0.1-0.5,0.1-0.8v-0.2c0-4.6-7.4-14.6-12.1-15.1h-1c-3.3,0-2.5,1.8-8.8,2.3
	c-15.8,1.1-39.9,2.7-57,3.5c-0.2,0-0.6,0-0.8,0c-0.6,0-1.1,0-1.5,0c-6.7,0-10.5-1.4-12.3-4.2c-0.7-1.2-1.4-1.8-1.9-1.8
	c-0.6,0-1.1,0.6-1.3,2c-0.2,0.8-0.2,1.7-0.2,2.6c0,7.1,5.5,15.6,11,15.8h0.8c3.9,0,5.6-2.5,11.2-3.8c15.7-3.8,40.8-7,52.4-8.5
	c0.8-0.1,1.4-0.1,2-0.1c1,0,1.4,0.2,1.4,0.7c0,0.6-0.5,1.4-1.3,2.7c-4.2,6.4-11.5,14.3-19.9,21.3C58.6,40.5,51,36,43.9,36
	c-1.7,0-3.3,0.2-4.9,0.8c-1.3,0.5-1.9,1-1.9,1.4s1,1,2.6,1.3c4,1,5.4,3.8,5.4,7.9c0,0.6,0,1.3-0.1,2c-1.9,20.2-15.5,41.2-33.5,56.9
	c-1.2,1-1.5,1.7-1.5,1.9c0,0.1,0,0.2,0.2,0.2c0.5,0,1.1-0.2,2-0.7c25.7-13.6,39.6-30,47-60c10.6-3.6,19.6-8.9,29.6-14.8
	C92.6,30.9,97.6,33,99,29.2z"/>
<path className="st0 svg-elem-2" d="M150.3,85.4c3.1,0,5.8-2.9,5.8-7.5c0-1.5-0.2-3.2-1-5.1c-1.3-3.7-4.3-7.5-8.1-10.7c5.1-7,9.5-14.4,13.7-22.5
	c2-4,6.3-2.5,7-7.1V32c0-4-7.5-11.2-12-11.2h-0.2c-3,0-3.2,2.7-7,3.3c-4.9,0.7-12.5,1.9-17.9,2c0.7-1.3,1.8-2.9,2.4-3.8
	c1.2-1.8,2.1-1.4,2.6-3.8c0.1-0.4,0.1-0.7,0.1-1.1c0-5.2-7.4-11-15.2-11c-1.7,0-3.2,0.2-4.8,0.7c-1.1,0.4-1.7,0.7-1.7,1.2
	c0,0.2,0.5,0.6,1.3,0.8c3,0.8,5,3.2,5,6.8c0,1.3-0.2,2.6-0.7,4.2c-3.5,11.4-16.8,35-32,48c-1.1,1-1.7,1.7-1.7,2
	c0,0.1,0.1,0.2,0.4,0.2c0.5,0,1.1-0.2,2-0.7c19.4-10.7,32.3-24,40.4-39.5c1.3,1.5,3,2.4,4.6,2.5c0.1,0,0.4,0,0.5,0h1
	c3.9,0,10-0.7,14.2-1.4c0.5-0.1,0.8-0.1,1.2-0.1c0.4,0,0.6,0.1,0.6,0.5c0,0.2-0.1,0.7-0.4,1.3c-2.9,7.6-7,15.4-12.1,22.7
	c-6.1-3.7-12.7-6.4-18.5-7.5c-1.1-0.2-1.8-0.4-2.3-0.4c-0.4,0-0.5,0.1-0.5,0.2c0,0.4,0.6,1,1.9,1.9c4,3.1,9.2,7.7,13.7,13
	c-14,17.3-33.1,32.7-53.8,42.7c-1.2,0.6-1.8,1.1-1.8,1.4c0,0.2,0.4,0.4,0.8,0.4c0.4,0,0.8,0,1.5-0.2c26.7-8.2,45.4-19.5,59.8-35.6
	c1.8,2.6,3.2,5.2,4.2,7.7C145.1,83.5,147.8,85.4,150.3,85.4z"/>
<path className="st0 svg-elem-3" d="M157.6,11.6c4.4,2.9,8.5,5.5,11.5,10.7c1.1,1.9,2.6,2.7,3.9,2.7c1.9,0,3.5-1.5,3.5-3.9c0-1.4-0.6-3.2-2.1-5.1
	c-3.3-4-8.7-6.2-13.9-6.2c-1,0-1.8,0-2.6,0.1c-0.8,0.1-1.2,0.4-1.2,0.6S156.8,11.1,157.6,11.6z"/>
<path className="st0 svg-elem-4" d="M182.4,5.9c-3.3-3.1-8-4.3-12.4-4.3c-1.7,0-3.2,0.2-4.8,0.5c-1.1,0.2-1.5,0.5-1.5,0.7c0,0.2,0.6,0.6,1.7,1
	c5.2,2,10,5.6,13.1,9.4c1.1,1.4,2.4,2,3.6,2c1.8,0,3.3-1.5,3.3-3.7C185.4,9.8,184.6,7.8,182.4,5.9z"/>
<path className="st0 svg-elem-5" d="M244.1,100.7c3.5,0,6.2-2.1,6.2-5.5c0-3-2.3-6.9-8.1-10.8c-10.8-7.4-31.3-12.1-49-12.3h-3
	c-2.1,0-3.1,0.2-3.1,0.6s0.8,0.8,2.7,1.4c23,8,35.7,14.5,47.1,23.8C239.4,99.8,241.9,100.7,244.1,100.7z"/>
<path className="st0 svg-elem-6" d="M195.9,10.8c14.5,6.8,24.4,15.6,30.8,20.7c2.6,2.1,5.2,3,7.4,3c3.3,0,5.7-2.3,5.7-5.2c0-2.7-1.9-6.1-6.5-9
	c-7.9-5-21.9-10.1-36.9-10.6h-0.8c-0.7,0-1.1,0.1-1.1,0.2C194.5,10.1,195,10.3,195.9,10.8z"/>
<path className="st0 svg-elem-7" d="M192,38.6c11.3,5.2,21.4,11.1,29.4,18.5c2.6,2.4,5.1,3.3,7.4,3.3c3.2,0,5.6-2.3,5.6-5.5c0-2.7-1.9-6.3-6.8-9.5
	c-6.1-3.9-19.8-8.5-35.6-8.6h-0.2c-1.3,0-1.9,0.1-1.9,0.4S190.6,37.9,192,38.6z"/>
<path className="st0 svg-elem-8" d="M350.5,29.2c0.1-0.4,0.1-0.5,0.1-0.8v-0.2c0-4.6-7.4-14.6-12.1-15.1h-1c-3.3,0-2.5,1.8-8.8,2.3
	c-15.8,1.1-39.9,2.7-57,3.5c-0.2,0-0.6,0-0.8,0c-0.6,0-1.1,0-1.5,0c-6.7,0-10.5-1.4-12.3-4.2c-0.7-1.2-1.4-1.8-1.9-1.8
	c-0.6,0-1.1,0.6-1.3,2c-0.2,0.8-0.2,1.7-0.2,2.6c0,7.1,5.5,15.6,11,15.8h0.8c3.9,0,5.6-2.5,11.2-3.8c15.7-3.8,40.8-7,52.4-8.5
	c0.8-0.1,1.4-0.1,2-0.1c1,0,1.4,0.2,1.4,0.7c0,0.6-0.5,1.4-1.3,2.7c-4.2,6.4-11.5,14.3-19.9,21.3c-1.2-5.1-8.8-9.6-15.8-9.6
	c-1.7,0-3.3,0.2-4.9,0.8c-1.3,0.5-1.9,1-1.9,1.4s1,1,2.6,1.3c4,1,5.4,3.8,5.4,7.9c0,0.6,0,1.3-0.1,2c-1.9,20.2-15.5,41.2-33.5,56.9
	c-1.2,1-1.5,1.7-1.5,1.9c0,0.1,0,0.2,0.2,0.2c0.5,0,1.1-0.2,2-0.7c25.7-13.6,39.6-30,47-60c10.6-3.6,19.6-8.9,29.6-14.8
	C344,30.9,349,33,350.5,29.2z"/>
<path className="st0 svg-elem-9" d="M379.1,35.8h0.6c7-0.1,27.5-3.2,38.9-3.2c1.3,0,2.5,0,3.6,0.1h1.1c5.1,0,7.9-2.1,7.9-4.6c0-2.9-4-6.5-13.1-6.8
	h-0.6c-3.9,0-5.6,1.9-9.9,2.6c-9.4,1.4-18.7,2.7-26.5,3.1c-1.3,0.1-2.5,0.1-3.8,0.1c-4.5,0-8.2-0.7-10.2-3.5c-0.6-0.8-1-1.2-1.3-1.2
	c-0.4,0-0.6,0.5-0.7,1.5v0.7C364.9,31.5,371.6,35.7,379.1,35.8z"/>
<path className="st0 svg-elem-10" d="M439.9,71c-1-0.1-1.8-0.2-2.5-0.2c-2.5,0-3.7,0.7-6.8,0.7h-0.4c-3.2,0-6.4-0.1-9.8-0.1
	c-18.8,0-39.2,1.1-53.1,2.5c-1.7,0.1-3.2,0.2-4.6,0.2c-5.4,0-8.9-1.2-11.1-3.9c-0.8-1-1.4-1.4-1.8-1.4c-0.4,0-0.6,0.4-0.6,1.3v0.6
	c0.1,6.8,7,15.6,12.1,16.2h0.2c2.4,0,3.3-1.2,6.3-2.4c7.9-3.1,22.7-5,38-5c12.9,0,26,1.2,35,4.3c1.9,0.6,3.7,1,5,1
	c3.7,0,5.4-1.9,5.4-4.3C451.4,76.8,447.4,72.2,439.9,71z"/>
<path className="st0 svg-elem-11" d="M153.3,137.7c0.3,0.9,0.9,1.3,1.6,1.3c0.9,0,1.9-0.8,1.9-2c0-0.2,0-0.4-0.1-0.6c-0.6-2.3-3.6-3.7-6.1-4.7
	c1.6-1.9,2.7-4,3.8-6.1c0.1-0.2,0.5-0.4,1.1-0.6c0.6-0.1,0.9-0.4,0.9-0.7c0-0.2-0.1-0.5-0.4-0.8c-0.7-0.7-1.9-1.7-2.7-2.3
	c-0.2-0.1-0.3-0.2-0.4-0.2s-0.2,0.1-0.4,0.2c-0.4,0.4-1.1,1.1-1.4,1.5c-0.2,0.2-0.3,0.3-0.7,0.3h-0.3v-4.3c0-0.5,0.1-0.8,0.8-1
	c0.5-0.2,0.8-0.4,0.8-0.7c0-0.2-0.2-0.5-0.8-0.6c-1.1-0.2-2.7-0.5-4.1-0.7c-0.1,0-0.2,0-0.3,0c-0.2,0-0.2,0-0.2,0.2s0,0.3,0.1,0.6
	c0.1,0.7,0.2,2.2,0.2,4c0,0.8,0,1.7,0,2.6h-0.3c-1.5,0-3.3-0.2-4.2-0.5c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.1,0.1v0c0,0,0,0.1,0,0.2
	c0.1,0.4,0.2,0.7,0.4,1c0.1,0.2,0.2,0.4,0.4,0.4c0,0,0.1,0,0.2,0c0.7-0.1,2.2-0.2,3.6-0.2h4.6c-1.7,5.2-4.8,10.5-9.5,15.1
	c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.2,0,0.2-0.1c1.9-1.1,3.6-2.4,5.3-3.9c0,5.3-0.1,9.3-0.2,14.1v0.2
	c0,0.8,0.2,1.3,1.4,1.3c0.2,0,0.4,0,0.6,0c1.7-0.2,2.1-0.4,2.1-1.3c0,0,0-0.1,0-0.1v-0.2c-0.1-3.1-0.2-11.1-0.2-17.2
	C152.6,134.4,152.9,136.4,153.3,137.7z"/>
<path className="st0 svg-elem-12" d="M173.9,126.3c-0.2-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0.2c-0.5,0.7-1.3,1.8-1.8,2.4
	c-0.2,0.3-0.4,0.3-0.8,0.3h-8c0-1.7,0-3.2,0-4.5v-3.1c3.8-0.3,7.4-0.9,10.6-1.8c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.4,0,0.9,0.2
	c0.2,0,0.3,0.1,0.4,0.1c0.3,0,0.5-0.2,0.5-0.4c0-0.2-0.1-0.4-0.4-0.7c-0.9-0.8-2.5-2-3.5-2.8c-0.2-0.1-0.3-0.2-0.4-0.2
	c-0.2,0-0.2,0.2-0.4,0.3c-2.2,1.8-4.5,3.4-7.9,4.5c-0.6-0.4-2.5-1.3-4-2c-0.2-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.2,0.1-0.2,0.3
	s0.1,0.6,0.1,1.1c0.1,1.1,0.3,4.1,0.3,7.6c0,3.8-0.2,8.2-0.8,11.5c-1,5.3-2.8,8.9-5,11.5c-0.1,0.2-0.2,0.3-0.2,0.4
	c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.2,0,0.3-0.1c3.2-2.1,6.5-5.4,8-11.2c0.7-2.5,0.9-6.3,1-9.8h5.3c0,1.9,0.1,4.4,0.1,7.1
	c0,4.6-0.2,9.6-0.2,12.9v0.2c0,0.9,0.3,1.2,1.3,1.2c0.2,0,0.4,0,0.6,0c1.8-0.1,2.1-0.4,2.1-1.3v-0.1c0-3.3-0.1-7.5-0.1-11.5
	c0-3.2,0-6.3,0-8.5h5.3c0.4,0,0.6-0.1,0.6-0.3c0-0.2-0.1-0.3-0.3-0.5C176.3,128.3,174.9,127.1,173.9,126.3z"/>
<path className="st0 svg-elem-13" d="M205.4,118.9c-3.3,0-7.3,4.8-9.8,15.3c-0.1,0.5-0.3,0.7-0.4,0.7s-0.4-0.3-0.5-0.8c-0.2-0.8-0.4-1.7-0.4-2.7
	c0-1.8,0.4-3.9,1.5-6.4c0.4-1,1.1-1.6,1.4-2.8c0-0.3,0.1-0.5,0.1-0.8c0-2.3-2.1-4.5-4.8-4.5c-0.4,0-0.6,0.1-0.6,0.2s0.1,0.3,0.2,0.5
	c0.4,0.6,0.7,1.7,0.7,2.9c0,0.6-0.1,1.2-0.2,1.9c-0.7,3.2-1.3,6.8-1.3,10c0,2.4,0.3,4.6,1.2,6.5c0.7,1.6,1.7,2.3,2.6,2.3
	c1.3,0,2.4-1.3,2.4-3.3c0-0.2,0-0.4,0-0.5c0-0.4,0-0.8,0-1.3c0-6.5,3.8-14.3,6.5-14.3c1.7,0,2.9,2.7,2.9,9.9
	c0,7.7-2.6,14.9-12.4,19.4c-0.4,0.2-0.5,0.3-0.5,0.4c0,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.4,0c15.2-3.1,16.3-14.2,16.3-20.3
	C211,123.3,208.6,118.9,205.4,118.9z"/>
<path className="st0 svg-elem-14" d="M312.3,132.2c-6.8-0.5-11-2.1-15.9-6.5h0.9c0.6-0.1,0.8-0.3,0.8-0.6s-0.2-0.5-0.6-0.6c-1.7-0.6-3-1-4.6-1.5
	c-0.2,0-0.3-0.1-0.4-0.1c-0.2,0-0.2,0.2-0.4,0.6c-2.5,4.9-8.4,9.9-15.1,13c-0.2,0.1-0.4,0.3-0.4,0.4s0.1,0.3,0.4,0.3
	c0.1,0,0.1,0,0.2,0c8.4-2.4,14.4-5.9,18.5-11.2c4.2,5.7,7.7,8.4,14.1,10.7c0.1,0,0.2,0,0.2,0c0.2,0,0.4-0.1,0.5-0.4
	c0.2-1.6,0.6-2.7,1.8-3.2c0.3-0.1,0.5-0.4,0.5-0.6C312.9,132.4,312.7,132.3,312.3,132.2z"/>
<path className="st0 svg-elem-15" d="M246.3,120.5c-1.1,0-2.3,0.1-3.4,0.3c-7.5,1.2-14.4,7.7-14.4,16.9c0,1.5,0.2,3,0.6,4.6c0.7,2.9,2.2,4.2,4.1,4.2
	c1.7,0,3.6-1.1,5.4-3c3.9-4.1,6.8-11,7-17v-0.2c0-1.2-0.6-3.2-1.9-4.3c0.8-0.1,1.6-0.2,2.4-0.2c7.1,0,11.2,4.4,11.2,10.7
	c0,0.3,0,0.6,0,1c-0.5,7.2-5.9,13.1-14.6,14.1c-0.4,0-0.6,0.2-0.6,0.2c0,0.1,0.2,0.2,0.8,0.2c0.4,0,0.8,0,1.3,0
	c11.1,0,17.2-5.7,17.3-14.3v-0.5C261.3,126.3,255.2,120.5,246.3,120.5z M243.5,126.4c-1.2,6.2-4.4,11.8-7.6,14.7
	c-0.6,0.6-1.3,0.9-1.8,0.9c-0.8,0-1.4-0.6-1.8-1.9c-0.4-1.2-0.6-2.5-0.6-3.9c0-6,3.8-12.7,11.1-14.1c0.4,0.6,0.8,1.7,0.8,3
	C243.6,125.6,243.5,126,243.5,126.4z"/>
<path className="st0 svg-elem-16" d="M278.5,127.8c5.2-1.7,9.3-4.8,11.5-7.4c0.3-0.4,0.5-0.4,0.8-0.4h0.4c0.6,0,0.8-0.2,0.8-0.5
	c0-0.2-0.2-0.4-0.5-0.6c-1.6-0.7-2.9-1.1-4.4-1.7c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.2,0.1-0.4,0.4c-0.8,2.6-4.5,7.1-8.2,9.6
	c-0.2,0.1-0.3,0.3-0.3,0.4c0,0.2,0.2,0.3,0.4,0.3C278.4,127.9,278.5,127.9,278.5,127.8z"/>
<path className="st0 svg-elem-17" d="M305.4,125.7c0.6,1,1.5,1.4,2.1,1.4c1.1,0,2-0.9,2-2.2c0-0.5-0.1-1.1-0.5-1.7c-1.5-2.5-6.1-4.5-11.3-5.5h-0.2
	c-0.3,0-0.4,0.1-0.4,0.3c0,0.1,0.1,0.3,0.4,0.4C302.9,120.9,303.8,123.3,305.4,125.7z"/>
<path className="st0 svg-elem-18" d="M306.8,137.1c-0.6-0.5-1.8-1.3-2.6-1.7c-0.3-0.2-0.5-0.2-0.6-0.2c-0.2,0-0.3,0.1-0.6,0.3
	c-0.4,0.3-1,0.9-1.5,1.4c-0.2,0.2-0.4,0.3-0.7,0.3h-12.8c0,0-2.9-1.2-4.2-1.6c-0.2-0.1-0.4-0.1-0.5-0.1s-0.3,0.1-0.3,0.3
	c0,0.2,0,0.4,0.1,0.6c0.3,0.9,0.4,2.9,0.4,5.2c0,2.7-0.2,6-0.2,8.4v0.2c0,1,0.6,1.2,1.5,1.2h0.5c1.6,0,2.2-0.3,2.2-1.5v-0.1
	c0-0.4,0-0.7,0-1.1h14.3c0,0.4,0,0.8,0,1.3c0,0.1,0,0.1,0,0.2c0,1.1,0.4,1.3,1.5,1.3c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.2,0
	c1.4-0.1,2.1-0.4,2.2-1.5v-0.1c0-1.9-0.1-4.8-0.1-7.1c0-1.2,0-2.3,0-2.9c0-0.8,0.2-0.8,0.9-1.2c0.4-0.2,0.6-0.5,0.6-0.8
	C307.3,137.5,307.1,137.3,306.8,137.1z M301.8,147.7h-14.3c0-1.8-0.1-3.8-0.1-5.7c0-1.4,0-2.7,0-4h14.3c0,1.4,0.1,3,0.1,4.7
	C301.9,144.4,301.8,146.1,301.8,147.7z"/>
<g>

	<path className="st1 svg-elem-19" d="M953,457.6c0-42.2,0-84,0-125.7c0.2-0.1,0.4-0.1,0.6-0.2c0.4,0.6,0.8,1.2,1.2,1.9c2.7,4.5,5.4,8.9,8,13.5
		c0.6,1,0.9,2.3,0.9,3.5c0,34.6,0,69.2,0,103.9c0,0.9,0,1.8,0,3c1-0.5,1.7-0.8,2.4-1.1c12.8-6.6,25.6-13.2,38.4-19.8
		c1.5-0.8,2.1-1.7,2.1-3.4c-0.1-21.2-0.1-42.4,0-63.6c0-1.4,0.5-2.9,1.2-4.1c2.8-4.6,5.8-9,9.3-13.4c0.1,0.7,0.2,1.4,0.2,2.1
		c0,28.7,0,57.4,0,86.1c0,1.5-0.5,2.2-1.8,2.8c-18.5,9.5-37,19-55.5,28.6c-1.3,0.7-2.4,0.6-3.7-0.1c-16.5-8.6-33.1-17.1-49.6-25.7
		c-1.6-0.8-2.7-0.8-4.3,0c-16.4,8.6-33,17-49.4,25.6c-1.6,0.8-2.7,0.8-4.3,0c-18.2-9.4-36.4-18.8-54.6-28.2
		c-1.7-0.9-2.2-1.9-2.2-3.7c0.1-54.7,0.1-109.5,0.1-164.2c0-2.8,0-5.6,0-8.8c0.9,0.3,1.5,0.5,2,0.8c18.2,9.4,36.5,18.7,54.6,28.2
		c1.8,0.9,3,0.8,4.7,0c16.3-8.5,32.7-16.9,49-25.4c1.6-0.8,2.8-0.9,4.5-0.1c8.1,4.3,16.3,8.4,24.5,12.7c0.9,0.5,1.7,1.7,2.1,2.7
		c1.1,3.1,1.9,6.3,2.8,9.4c0.2,0.7,0.4,1.4,0.8,2.9c-9.3-4.8-17.9-9.2-26.9-13.8c-0.1,1.1-0.1,1.9-0.1,2.8c0,48.7,0,97.5-0.1,146.2
		c0,1.9,0.6,2.9,2.3,3.7c12.7,6.5,25.4,13,38,19.6C950.9,456.6,951.7,456.9,953,457.6z M899.2,284c-1,0.5-1.7,0.8-2.4,1.1
		c-12.6,6.5-25.2,13.1-37.8,19.4c-2,1-2.7,2.1-2.7,4.4c0.1,47.2,0,94.3,0,141.5c0,2.2,0,4.5,0,7c1.2-0.6,1.9-0.9,2.7-1.3
		c12.6-6.5,25.2-13,37.8-19.5c1.9-0.9,2.5-2,2.5-4.1c-0.1-37,0-73.9,0-110.9C899.2,309.4,899.2,296.9,899.2,284z M802.6,283.9
		c0,1.1,0,1.7,0,2.3c0,49,0,98.1,0,147.1c0,1.5,0.4,2.3,1.8,3.1c13,6.6,25.9,13.3,38.9,20c0.6,0.3,1.2,0.5,2,0.9
		c0.1-0.8,0.1-1.4,0.1-2c0-49.1,0-98.2,0-147.4c0-1.6-0.6-2.3-2-3c-8-4.1-15.9-8.2-23.9-12.3C814.1,289.8,808.6,287,802.6,283.9z"/>

	<path d="M991,370.9c-2.9-4.4-5.8-8.5-8.6-12.8
		c-11.8-18.2-23.3-36.5-32-56.4c-2.8-6.3-5.4-12.9-6.6-19.6c-2.8-15.2,2-28.5,12.7-39.4c13.5-13.8,30.1-18.1,48.5-12.6
		c18.4,5.5,29.4,18.4,33.4,37.1c1.8,8.2,0.4,16.3-2.4,24.1c-5.2,14.4-12.3,27.8-20.1,40.8c-7.4,12.3-15.3,24.2-23,36.3
		C992.3,369.1,991.7,369.9,991,370.9z M991.1,299.3c14.3,0,25.9-11.7,25.8-26c-0.1-14.3-11.8-25.8-26.1-25.7
		c-14.1,0.1-25.7,11.7-25.7,25.8C965.2,287.7,976.9,299.3,991.1,299.3z" className="svg-elem-20"/>
</g>
</svg>


            </div>
        </div>
    )
}

export default SvgLogo;

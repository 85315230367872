
const initialState = {
  dbCalling:false,
  newses:{},
};



const reducer = (state = initialState, action) => {
  const {eventDate, posts, tag, id, val, key, items} = action.payload || {};

  switch (action.type) {
    case "ASN_FAILED":
      const {msg, stop} = action.payload || {};
      console.log("ASN_FAILED", msg);
      return state;

    case 'SET_OBJ':
      return action.payload ? { ...state, ...action.payload } : state;

    case 'SET_PARAM':
      return { ...state, [key]:val };

    default:
      return state;
  }
};

export default reducer;
